<script>
  import { Carousel, Slide } from "vue-carousel";
import {
    ArrowUpIcon,
    ArrowRightIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    YoutubeIcon,
    GitlabIcon,
    MailIcon,
    UserPlusIcon,
    UsersIcon,
    MessageCircleIcon,
    BellIcon,
    ToolIcon,
    PhoneIcon,
    BookmarkIcon,
    ItalicIcon,
    GlobeIcon,
    GiftIcon,
    MapPinIcon
} from 'vue-feather-icons';
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

/**
 * Account-profile component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Carousel,
        Slide,
        Footer,
        ArrowUpIcon,
        FacebookIcon,
        InstagramIcon,
        TwitterIcon,
        LinkedinIcon,
        GithubIcon,
        YoutubeIcon,
        GitlabIcon,
        MailIcon,
        UserPlusIcon,
        UsersIcon,
        MessageCircleIcon,
        BellIcon,
        ToolIcon,
        PhoneIcon,
        BookmarkIcon,
        ItalicIcon,
        GlobeIcon,
        GiftIcon,
        MapPinIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />

    <!-- Hero Start -->
    <section class=" bg-profile d-table w-100 multi-color-bg ">








      <div class="bg-overlay " style="margin-bottom: -80px; background: url('/images/real/build2.png') center bottom no-repeat;"></div>





      <div class="bird-container bird-container-one">
   <div class="bird bird-one"></div>
</div>        
<div class="bird-container bird-container-two">
  <div class="bird bird-two"></div>
</div>  
 <div class="bird-container bird-container-three">
   <div class="bird bird-three"></div>
 </div> 
 <div class="bird-container bird-container-four">
   <div class="bird bird-four"></div>
 </div>












        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card bg-company public-profile border-0 rounded shadow" style="z-index: 1;">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-lg-2 col-md-3 text-md-left text-center">
                                    <img src="images/karukaj-digital-logo.png" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                                </div>
                                <!--end col-->

                                <div class="col-lg-10 col-md-9">
                                    <div class="row align-items-end" style= "margin-top: -20px;">
                                        <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                                            <h3 class="title mb-0 company-title">Karukaj <span> DIGITAL</span></h3>
                                            <small class="text-muted h6 mr-2">We offer creative services to creative businesses</small>  

                                            <ul class="list-inline mb-0 mt-3 pb-2">
                                                <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted" title="Instagram">
                                                </a>
                                                   
                                                  <a href="javascript:void(0)" class="text-muted"><map-pin-icon class="fea icon-sm mr-2"> </map-pin-icon> <strong>Address: </strong>
                                                 
                                                    7th Floor, Ka 215/B, JS Tower, Merul Badda, Dhaka, BD.</a>
                                                    <br>
                                                    <a href="javascript:void(0)" class="text-muted"><globe-icon class="fea icon-sm mr-2"></globe-icon>  <strong>IT Main Stream URL : </strong><u>it.karukaj.com.bd</u></a>
                                                    <br>   
                                                  </li>
                                            </ul>
                                        </div>
                                        <!--end col-->
                                        <div class="col-md-5 text-md-right text-center">



 <div class="media align-items-center mt-3">
                                        
                                        <div class="media-body">
                                            
                                          <img src="images/whatsappQRcode.png" class=" responsive-hide avatar avatar-80x" alt="">
                       
                                        </div>
                                    </div>


                                            <ul class="list-unstyled social-icon social mb-0 mt-4">


                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.facebook.com/karukaj.digital" class="rounded">
                                        <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://twitter.com/karukaj_digital" class="rounded" v-b-tooltip.hover title="Messages">
                                        <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                </a></li>

                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.linkedin.com/company/karukajdigital" class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                                        <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.youtube.com/karukaj" class="rounded">
                                        <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.pinterest.com/karukaj_digital" class="rounded">
                                        <globe-icon class="fea icon-sm fea-social"></globe-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://karukaj.github.io" class="rounded">
                                        <github-icon class="fea icon-sm fea-social"></github-icon>
                                    </a></li>


                                 <li class="list-inline-item ml-1"><a  target="_blank" href="https://karukaj-digital.business.site" class="rounded" v-b-tooltip.hover title="Notifications">
                                 <bell-icon class="fea icon-sm fea-social"></bell-icon>
                                 </a></li>

                                            </ul>
                                            <!--end icon-->
                                        </div>
                                        <!--end col-->
                                    </div>
                                    <!--end row-->
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--ed container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- Profile Start -->
    <section class="section mt-60">












  
      <div class="container" >
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="section-title">
   <br> 
                   <h4 class="title mb-4">
                  How we work<br />outside the box
                </h4>
  
                
                         <p class="text-muted para-desc mb-0 mx-auto"> <span class="my-color-1 font-weight-bold">Karukaj DIGITAL</span> <br/>
                         We offer creative services for creative businesses and create digital IT opportunities for future business success.<br/></p>
  
                     <div class="section-title ml-lg-4"  > <br> 
   <p><i class="mdi mdi-chevron-right"></i
                > <span class="responsive-small my-color-1 font-weight-bold"> We serve all industries</span> 
                <br>
                <span class="text-muted font-weight-bold">START-UPS | B2B | B2C | E-COMMERCE | CORPORATE | NGO</span>  
  </p>
              </div>     
  
              <p> <span class="text-muted para-desc mb-0 mx-auto"> We provide customer satisfaction online, 
                where the participation of expert members for joint or independent work is ensured.</span></p>
                
                
                
   


                
  
                     <div class="section-title ml-lg-4" style="margin-bottom: 20px;">

                      <a href="javascript:void(0)" class="mt-3 h6 my-color-1"
                  > Your comfort is our priority. Enroll Now <i class="mdi mdi-chevron-right"></i
                ></a>
  
              </div>     
           
              </div>
            </div>
            <!--end col-->
  
            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
  
  
  
              <div class="ml-md-4">
                  <h5 class="mt-3 h6 font-weight-bold">Our Highlights</h5>
  
                <div class="progress-box">
                  <h6 class="title text-muted">Website Design & Development</h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 94%"
                    >
                      <div class="progress-value d-block text-muted h6">94%</div>
                    </div>
                  </div>
                </div>
                
               <!--end process box-->
                <div class="progress-box mt-4">
                  <h6 class="title text-muted">Brand Identity & Graphic Designing</h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 93%"
                    >
                      <div class="progress-value d-block text-muted h6">93%</div>
                    </div>
                  </div>
                </div>
                  <!--end process box-->
                 <div class="progress-box mt-4">
                  <h6 class="title text-muted">Expertise in Digital Marketing</h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 90%"
                    >
                      <div class="progress-value d-block text-muted h6">90%</div>
                    </div>
                  </div>
                </div>
                <!--end process box-->
                  <div class="progress-box mt-4">
                  <h6 class="title text-muted">Photography & Multimedia Production</h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 83%"
                    >
                      <div class="progress-value d-block text-muted h6">83%</div>
                    </div>
                  </div>
                </div>
  
  
                <!--end process box-->
                <div class="progress-box mt-4">
                  <h6 class="title text-muted">Audit & Creative Thinking Experience </h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 81%"
                    >
                      <div class="progress-value d-block text-muted h6">81%</div>
                    </div>
                  </div>
                </div>
                <!--end process box-->
  
                <div class="progress-box mt-4">
                  <h6 class="title text-muted">PPC/Google Adwords Campaigns</h6>
                  <div class="progress">
                    <div
                      class="progress-bar position-relative bg-primary"
                      style="width: 65%"
                    >
                      <div class="progress-value d-block text-muted h6">65%</div>
                    </div>
                  </div>
                </div>
  
                <!--end process box-->
  
  <br>
  
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
  
  
  
  






















        <div class="container mb-4 mt-lg-3">
            <div class="row">



                
                <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none ">
                    <div class="sidebar sticky-bar p-4 rounded shadow ">
                        <div class="widget">


<p class="widget-title"></p>
<p class="text-muted mb-0">

  <b>Status: </b> Live Company<br>

<b>URL for IT Stream: </b>
<br><u>it.karukaj.com.bd</u>
<br>
    <b>Number of employees:</b>
    <br>7/8 everyday in office 
    <br>
    <b>CRM Address: </b><br>
    business.karukaj.com.bd<br>
    <b>Principal Activity(ies):</b> <br>
General Contractor<br>


<b>Workplaces:</b><br>
On-site · Hybrid · Remote<br>

<b>Signle Task Price:</b> BDT 999<br>
<b>Avg. Hourly Rate:</b> $15 – $40 / hr<br>
<b>Minimum Project Price:</b>   $150+<br>
<b>Commercial Focus:</b>   B2B, B2C<br>
<b>Country Of Incorporation (ies):</b><br>
Dhaka, Bangladesh.<br>

<b>Service Area:</b><br> 
Local/International, Any 
 </p>   


                  
                      
                        </div>











                        

                        

                        <div class="widget mt-4 pt-2">
                            <h5 class="widget-title">About Projects:</h5>


                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">We Accept Project</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:60%;">
                                        <div class="progress-value d-block text-muted h6">60%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->

                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Successful Happy Client</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:95%;">
                                        <div class="progress-value d-block text-muted h6">95%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Returning Customer</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:80%;">
                                        <div class="progress-value d-block text-muted h6">80%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->



     
                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Global Client</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:60%;">
                                        <div class="progress-value d-block text-muted h6">60%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->
                            <div class="progress-box mt-4">
                                <h6 class="title text-muted">Local Client</h6>
                                <div class="progress">
                                    <div class="progress-bar position-relative bg-primary" style="width:40%;">
                                        <div class="progress-value d-block text-muted h6">40%</div>
                                    </div>
                                </div>                              
                            </div>
                            <!--end process box-->



                        </div>

                        <div class="widget">
                            <div class="row">
 




                            </div>
                            <!--end row-->
                        </div>







                        <div class="widget mt-4 pt-2">
                            <h5 class="widget-title">Online Presence</h5>
                            <ul class="list-unstyled social-icon mb-0 mt-4">
                                <li class="list-inline-item"><a href="https://www.facebook.com/karukaj.digital" class="rounded">
                                        <facebook-icon class="fea icon-sm fea-social"></facebook-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.instagram.com/karukaj.digital" class="rounded">
                                        <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.twitter.com/karukaj_digital" class="rounded">
                                        <twitter-icon class="fea icon-sm fea-social"></twitter-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://www.linkedin.com/company/karukajdigital" class="rounded">
                                        <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://karukaj.github.io/" class="rounded">
                                        <github-icon class="fea icon-sm fea-social"></github-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://youtube.com/karukaj" class="rounded">
                                        <youtube-icon class="fea icon-sm fea-social"></youtube-icon>
                                    </a></li>
                                <li class="list-inline-item ml-1"><a  target="_blank" href="https://blog.karukaj.com.bd" class="rounded">
                                        <gitlab-icon class="fea icon-sm fea-social"></gitlab-icon>
                                    </a></li>
                            </ul>
                            <!--end icon-->

                        
                        </div>

<br><b>
We're managing 10+ IT businesses<br>
Outsourced from 30+ Companies<br>
Get 3000+ Comments in Blog<br>
200+ Five Star Reviews in Marketplace<br>
50+ Service Reviews inside Dhaka<br>

</b>









                    </div>







                </div>
                <!--end col-->

                <div class="col-lg-8 col-12">
                    <div class="border-bottom pb-4">



                                                <div class="d-flex mb-4">
                              <span class="my-color-1 h1 mb-0"><span class="counter-value display-1 font-weight-bold" data-count="15">10</span>+</span>
                              <span class="h6 align-self-end ml-2">Years <br> Experience</span>
                          </div>






                        <h5>Summary</h5>   <br>
                        <p class="text-muted mb-0">
                            Karukaj is an independent and innovative full-service digital marketing agency 
                            that helps startups & midsize companies scale their growth. 
                            We plan & execute unique marketing strategies for short-term and long-term success. 
                            Our in-house team brings full impact across all of your marketing channels, 
                            empowering you to focus on what matters most.</p><br>

                              <b>Mission</b>   <br>
                             <p class="text-muted mb-0">
                            To create outstanding visual content that promotes business and inspires the world.</p>
                                           
                             <b>Vision</b>
                              <br>
                           <p class="text-muted mb-0"> We believe in the importance of improving the lives of others.</p>



                    </div>

                    <div class="border-bottom pb-4">
                        <br><br>
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <h5>Details</h5>
                                <div class="mt-4">

                                    <div class="media align-items-center mt-3">
                                        <italic-icon class="fea icon-ex-md text-muted mr-3"></italic-icon>
                                        <div class="media-body">
                                            <h6 class="my-color-1 mb-0"> Stream Name:</h6>
                                            <a href="javascript:void(0)" class="text-muted">Karukaj</a>
                                        </div>
                                    </div>





                                    <div class="media align-items-center">
                                        <mail-icon class="fea icon-ex-md text-muted mr-3"></mail-icon>
                                        <div class="media-body">
<br>
                                            <h6 class="my-color-1 mb-0"> Email Address:</h6>
                                          <img src="images/ml.png" alt="">
                                            
                                        </div>
                                    </div>





                                    <div class="media align-items-center mt-3">
                                        <map-pin-icon class="fea icon-ex-md text-muted mr-3"></map-pin-icon>
                                        <div class="media-body">
                                            <h6 class="my-color-1 mb-0"> Address:</h6>
                                            <a href="javascript:void(0)" class="text-muted">7th Floor, Ka 215/B, JS Tower, Merul Badda, 
                                              <br>Gulshan, Dhaka, BD.</a>
                                        </div>
                                    </div>

                      
                                     <div class="media align-items-center mt-3">
                                        <phone-icon class="fea icon-ex-md text-muted mr-3"></phone-icon>
                                        <div class="media-body">
                                            <h6 class="my-color-1 mb-0"> Contact (Toll Free Number)</h6>
                                            <p class="text-muted mb-0">880-9696021541</p>
                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end col-->

                            <div class=" col-md-6 mt-4 pt-2 pt-sm-0">
                                <h5>Credibility</h5>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/dhaka-north-city-corporation-logo.png" class="avatar avatar-47x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Government Approved</h4>
                                        <p class="text-muted mb-0">Dhaka North, Dhaka</p>
                                    </div>
                                </div>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/satis2.png" class="avatar avatar-47x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Legitimate IT Business</h4>
                                        <p class="text-muted mb-0">No branch anywhere else</p>
                                    </div>
                                </div>

                                <div class="media key-feature align-items-center p-3 rounded shadow mt-4">
                                    <img src="images/offer3.jpg" class="avatar avatar-47x" alt="">
                                    <div class="media-body content ml-3">
                                        <h4 class="title mb-0">Satisfaction Guaranteed</h4>
                                      <p class="text-muted mb-0">with professionalism</p>

                                    </div>
                                </div>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
<br><br>
                    <h5 class="mt-4 mb-0">What makes us different</h5>
          

<p class="text-muted mb-0">
We have no long term contracts or commitments. We charge on a month to month 
basis to ensure that we are working hard for you each and every month to keep 
you as a client.</p>
<br>

<p class="text-muted mb-0">
We don’t take on every bit of business that comes our way just to increase 
“numbers”, that’s not why we’re here. We also do outsourcing and familiar with the world of internet marketing, so we do what’s right for our clients, even 
if that means turning away a potential client because we don’t think a certain 
product or approach will work for them.</p>
<br>

<p class="text-muted mb-0">
We’re not just another “agency”, we are consultants. We think long 
term and value our customers growth with their business. We have the mindset to 
do what’s in your best interest and we will guide you down the right path, 
making the most out of every dollar you spend with us.</p>  


<br>
<h5> <span class="my-color-1 font-weight-bold">Ask Karukaj DIGITAL. "880-9696-021-541" TOLL FREE NUMBER</span> </h5>



  
                </div>
                <!--end col-->
            </div>
            <!--end row-->

      


        </div>
        <!--end container-->







    </section>
    <!--end section-->










  



    <section
      class="section pt-0 mt-4 pt-2"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build.png') +
          ') bottom no-repeat',
      }"
    >
  





 <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Are you ready to
jump-start your Besiness?</h4>

              <p class="text-muted para-desc mx-auto mb-0">
                Join our mission to provide industry-leading services to businesses around the globe.</p>

          

                        <div class=" pt-2">


                          <router-link  class="btn btn-primary font-weight-bold mt-4 text-center" to="/estimate"  v-b-tooltip.hover title="Let's start your sutable business project promotions."   ><i class="mdi mdi-email"></i> Submit Estimate Form<i class="mdi mdi-chevron-right"></i> </router-link> 




               
                        </div>
                               
                


                        <br><br><br>

 






                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->




            <div class=" container mt-100 mt-60 bird-container bird-container-one">
   <div class="bird bird-one"></div>
</div>        
<div class="bird-container bird-container-two">
  <div class="bird bird-two"></div>
</div>  
 <div class="bird-container bird-container-three">
   <div class="bird bird-three"></div>
 </div> 
 <div class="bird-container bird-container-four">
   <div class="bird bird-four"></div>
 </div>






    <div class="running-taxi"> 
      <div class="taxi"></div>             
      <div class="taxi-2"></div>             
      <div class="taxi-3"></div> 
    </div>










 
  </section>




















    <section
      class="section bg-company2">
      <div class="explore-login-backg"></div>



      
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-7">









            <div class="section-title text-md-left text-center">




              <h6 class="my-color-1 mt-40"><br/>
                You are just a few clicks away;</h6>
              <h4 class="title text-light mb-4">
                let's start your business project 
              </h4>


<h4 class="font-weight-bold my-color-1">Our Approach</h4> 
  
                                <ul class="responsive-small list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="my-color-1 h5 mr-2"
  
                      ><i class="uil uil-hunting align-middle"></i></span
                    >Provide maximum returns to clients on their investments
                  </li>
                  <li class="mb-0">
                    <span class="my-color-1 h5 mr-2"
                      ><i class="uil uil-hunting align-middle"></i></span
                    >Support clients to achieve their online goals in any time zone
                  </li>
                  <li class="mb-0">
                    <span class="my-color-1 h5 mr-2"
                      ><i class="uil uil-hunting align-middle"></i></span
                    >Commit to drive results without compromising integrity.
                  </li>
                </ul>       



              <p class="text-dim mb-0 para-desc">So, save your time to create something awesome that will make you proud and dominate your competition. 
 </p>


             







              <div class="row justify-content-center">
                <div class="col-lg-12 mt-4">
                  <carousel
                id="owl-fade"
                class="owl-carousel owl-theme push-on-left "
                dir="ltr"
                :per-page="1"
                :autoplay="true"
                :loop="true"
                  >



                    <Slide>
                      <div class="media customer-testi m-2 ">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            eCommerce Website Development <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> In addition to Laravel framework & Vue web application development, we also offer self-manageable popular WordPress CMS to build eCommerce website businesses of any size from large to small. If necessary, we also provide our own custom PHP framework for eCommerce website development. Indeed, we offer robust business strategies and advanced top-notch development technology considering customer satisfaction that will increase your sales.
                          </p>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div class="media customer-testi m-2">
           
                        <div
                          class="media-body  p-3 sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            UI/UX and Logo Design Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card "> Building experiences across different mobile and websites. 
                            We are a leading UX/UI Design Company in Bangladesh. 
                            User experience means creating value for the product by providing a great product experience to the end-user. 
                            This means we care about the end customer and strive to deliver products that people truly enjoy using.
                          </p>
                        </div>
                      </div>
                    </Slide>


                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Business Website Design ( with UI/UX) <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> We provide UI / UX-ready responsive website design and development for any type of business website that aims to boost your search rankings, increase your online exposure, and convert your page.
                          </p>
                        </div>
                      </div>
                    </Slide>

 
                    





                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            WordPress Website Design and Development <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> In addition to creating any custom design theme for WordPress and creating quality UI and UX design, we have 13+ Years of expertise in various features. Therefore, our creative designers develop eye-catching fast, and secure WordPress Web Design sites tailored to your business needs.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Custom Website Development <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> We understand that each website is unique. Our custom web development solutions provide you with a reliable, customized, and user-friendly site. So, you can hire our web developer to build a custom website that fulfills your unique requirements. Unlike using a pre-built template for your site, a customized website is specifically created for you.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Result-Driven SEO Service in Bangladesh <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> At Karukaj DIGITAL, we offer an affordable variety of SEO services to help your business grow. Our flexible SEO packages are focused on building a strong foundation for your online success, offering countless ways to reach your target customers—wherever they are searching.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                        
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Link Building Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p> Link building is an essential and cost-effective digital marketing strategy. It uses backlinks to rank your website. But, you may not know which link will increase or decrease the ranking. This means that you need to know which is a high-quality backlink or which is a nofollow link or which is a dofollow site you will use. We understand all these things from our experience and we offer to create backlinks for your website/blog/page through blog comments, article directories, web 2.0 sites, directories, etc.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Automated Email Marketing Services <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> Use email marketing automation service to turn your current client back into a customer again. This means that you can increase your sales by repeatedly returning to your website at a specified value i.e. coupons / special discounts or some free offers. Where e-mail marketing automation systems will automatically send some newsletters to your clients to visit your product page/website after a specified time. As a result of this scheduled email series, real visitors are present on your website all the time and new customers on your website can get stuck like a magnet.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Social Media Graphics Kit <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> It is for brands and influencers who take branding and influencing seriously. That means we provide social media kits in the form of sets for your product or service for your new/old Facebook Page/ Instagram Business page / Pinterest page and other social media sites. These social media kits will help you design amazing graphics for your social media campaigns like a pro.
                          </p>
                        </div>
                      </div>
                    </Slide>









                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Video Animation Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> Video Animation Services that Will Help Tell your Story to the Audience Easily. So, convey your message and let your brand stand out with our animated video production service. "Karukaj DIGITAL" is also a creative video production company specializing in animated explainer video production for the startup to enterprise businesses.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            PPC Online Advertising Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> Karukaj DIGITAL is another PPC management company that uses paid search to drive sales for your business. Whether you want to increase conversions, website traffic, or both, our tech-enabled PPC services will help you achieve your goals, plus measure and maximize your ROI (Return on Investment) from PPC.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Local SMS Marketing <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> If you want to introduce and sell your product or service online, the best way is to notify your target customer via email and SMS. Therefore, our strategy will give you an effective solution to sending many SMS and emails including finding the email and phone number of the right targeted customer.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Online Reputation Management <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> BAs a small business owner, you must actively monitor your company's Online Reputation and these management tactics can include any combination of content creation, review acquisition, social media marketing, search engine optimization, crisis management, content removal, public relations, and online. . monitoring. So, determine what you want to accomplish and set a monthly budget with an anticipated timeframe. Also, list any additional features or strategies you want.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Research & Data Entry Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> You can depend on "Karukaj DIGITAL" reliable data entry services to improve efficiency and develop a powerful, accessible database of key information. This gives you the support you need to optimally utilize resources and confidently pursue core business goals.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Premium Domain Name Sales Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> You may think that only by listing your quality domain as a premium domain in the auction marketplace will your domain be sold. Basically, you only need one buyer but it takes a lot of work to find him. Some more strategies are needed to sell such a domain or website. Talk to our premium domain broker team or experts today. They will help you to sell, acquire, and find the right buyer for your premium domain, so that sales can happen quickly.
                          </p>
                        </div>
                      </div>
                    </Slide>










                    <Slide>
                      <div class="media customer-testi m-2">

                        <div
                          class="media-body  p-3 shadow sweet-bg-container background--black-clients position-relative">
                          <h6 class="font-weight-bold my-color-1">
                            Blogger Custom Theme Design Service <br/>                          
                            <small class="text-muted">
                            </small> 
                          </h6>
                          <p class="company-card"> Due to Lifetime Free Blog (without hosting cost per year), many people create blogs on the Blogger platform but most people are dissatisfied with the design. So we are happy to provide you Blogger Template Design Service / Creation Service, which is alike a WordPress blog. Or you can hire us to fix any templates issue or add new features in addition to adding a . COM domain or doing SEO.
                          </p>
                        </div>
                      </div>
                    </Slide>














                  </carousel>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>









            

          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card rounded shadow border-0">
              <div class="card-body">
                <h5 class="text-center">Free Signup to see Details</h5>

                <form>
                  <div class="row mt-4">
                    <div class="col-lg-12">
                      <iframe width="auto" height="380" src="https://business.karukaj.com.bd/forms/wtl/aa4793a5caccf127bf6bbd2c5d5fb812" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" allowfullscreen></iframe>
                    </div>
                    <!--end col-->

                

                    <div class="col-lg-12 mb-0">
                  
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




      
    </section>
    <!--end section-->

























    <!-- Profile End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>
